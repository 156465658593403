export const mobileMenuOn = {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
};

export const mobileMenuOff = {
  display: "none",
};
